import { mapIsoStringToDate } from '@metaswiss/lib';
import { isValid, parse, parseISO } from 'date-fns';

export type GroupData<T> = {
  [day: string]: T[];
};

const isValidDate = (dateString: string): boolean => {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/;
  const simpleDatePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

  const isDateValid = (date: Date): boolean => isValid(date);

  if (isoDatePattern.test(dateString)) {
    const parsedDate = parseISO(dateString);
    return isDateValid(parsedDate);
  }

  if (simpleDatePattern.test(dateString)) {
    const parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm', new Date());
    return isDateValid(parsedDate);
  }

  return false;
};

export const groupDataByDate = <T extends { createdAt: string; updatedAt?: string }>(
  inputData: T[]
): GroupData<T>[] => {
  const groupedData: GroupData<T> = {};
  inputData.forEach((item) => {
    const createdAtDate = isValidDate(item.createdAt) ? mapIsoStringToDate({ date: item.createdAt }) : item.createdAt;

    const day = createdAtDate;

    if (!groupedData[day]) {
      groupedData[day] = [];
    }
    groupedData[day].push({
      ...item,
      createdAt: createdAtDate,
      updatedAt: item.updatedAt,
    });
  });

  const mapGroupedData = Object.entries(groupedData).map(([day, values]) => ({
    [day]: values,
  }));

  return mapGroupedData;
};

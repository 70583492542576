import { UpdatePrivateUserRequestV4 } from '@metaswiss/api';
import { mapDateToIsoString } from '@metaswiss/lib';
import {
  Button,
  CalendarIcon,
  FormDatePicker,
  FormInput,
  FormSelectionPopup,
  Globe04,
  InputPhoneNumber,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { ItemProps } from '@metaswiss/ui-kit/src/components/molecules/selection-popup/types';
import { QueryObserverResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  GlobalError,
  KeepStateOptions,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';

import { api } from '../../../../../api/msApi.ts';
import { AssignedUserAssetEnum } from '../../../../../enums/assignedUserAssetEnum.ts';
import { ApiResource } from '../../../../../enums/resource.enum.ts';
import { AppState, useAppState } from '../../../../../global-state/zustand.ts';
import { useCountries } from '../../../../../hooks/use-countries/useCountries.ts';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { swissPhonePrefix } from '../../../../../shared/constants/swissPhonePrefix.ts';
import { defaultUser } from '../../../../../shared/helpers/defaultUser.ts';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper.ts';
import { setFieldValues } from '../../../../../shared/helpers/setFieldValues.helper.ts';
import { parseFormObjectPrivateUser } from '../mappers/privateUser.mapper.ts';
import { ProfileFormData, profileSchema } from '../schemas/profile.schema.ts';
import { ButtonWrapper, FullRowWrapper, ProfileInfoContainer, Wrapper } from '../styles/profile.styles.ts';

type Props = {
  isEditMode: boolean;
  isDirty: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setValue: UseFormSetValue<ProfileFormData>;
  getValues: UseFormGetValues<ProfileFormData>;
  errors: Partial<FieldErrorsImpl<DeepRequired<ProfileFormData>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  };
  control: Control<ProfileFormData>;
  isValid: boolean;
  handleSubmit: UseFormHandleSubmit<ProfileFormData>;
  isPictureUpdated: boolean;
  cancelHandler: () => void;
  refetchImage: () => Promise<QueryObserverResult>;
  reset: (data?: Partial<ProfileFormData>, keepStateOptions?: KeepStateOptions) => void;
};

export const PrivateUserProfile: FC<Props> = ({
  isEditMode,
  setIsEditMode,
  setValue,
  getValues,
  errors,
  isDirty,
  isValid,
  control,
  isPictureUpdated,
  cancelHandler,
  refetchImage,
  reset,
}) => {
  const { textTranslation } = useTextTranslation();
  const {
    countriesPhoneNumberPrefixesAsDropdown,
    countriesNationalityAsDropdown,
    selectedCitizenship,
    selectedPhoneNumberPrefix,
  } = useCountries();

  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const queryClient = useQueryClient();

  const [flagSubmit, setFlagSubmit] = useState<boolean>(false);

  const { mutate, isLoading } = useMutation({
    mutationKey: getQueryKey(ApiResource.PRIVATE_USER, user.id),
    mutationFn: (data: ProfileFormData) => {
      const updateData: Partial<UpdatePrivateUserRequestV4> = {
        birthDate: data?.birthDate ? mapDateToIsoString(data.birthDate) : undefined,
        citizenship: data.citizenship?.label,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        phoneNumberPrefix: data.phoneNumberPrefix.meta,
        picture: isPictureUpdated ? data.picture : undefined,
      };
      return api.users.updatePrivateDetails(user.id, updateData as UpdatePrivateUserRequestV4);
    },
    onSuccess: async (response) => {
      setIsEditMode(false);
      setFieldValues<ProfileFormData>(
        parseFormObjectPrivateUser(
          response,
          selectedCitizenship(response?.privateUser?.citizenship),
          selectedPhoneNumberPrefix(response?.phoneNumberPrefix)
        ),
        setValue
      );
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.ASSET, user.id) });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.PICTURE}/${user.id}`),
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.THUMBNAIL}/${user.id}`),
      });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PRIVATE_USER, user.id) });
      await refetchImage();
      reset(
        parseFormObjectPrivateUser(
          response,
          selectedCitizenship(response?.privateUser?.citizenship),
          selectedPhoneNumberPrefix(response?.phoneNumberPrefix)
        )
      );
    },
  });

  const defaultPhoneNumberPrefixCountry = useMemo(
    () => countriesPhoneNumberPrefixesAsDropdown.find((item: ItemProps) => item.meta === swissPhonePrefix),
    [countriesPhoneNumberPrefixesAsDropdown]
  );

  useEffect(() => {
    if (!getValues(profileSchema.phoneNumberPrefix)?.value && isEditMode) {
      const swiss = countriesPhoneNumberPrefixesAsDropdown.find((item: ItemProps) => item.meta === swissPhonePrefix);
      if (swiss) {
        setValue(profileSchema.phoneNumberPrefix, swiss);
      }
    }
  }, [countriesPhoneNumberPrefixesAsDropdown, getValues(profileSchema.phoneNumberPrefix), isEditMode]);

  const handleDateSelection = (date: string) => {
    setValue(profileSchema.birthDate, date, { shouldValidate: true, shouldDirty: true });
  };

  const handlePhonePrefixSelection = (item: ItemProps) => {
    setValue(profileSchema.phoneNumberPrefix, item, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit = (data: ProfileFormData) => {
    setFlagSubmit(true);
    mutate(data);
  };

  useEffect(() => {
    if (!isEditMode) {
      if (flagSubmit) {
        setFlagSubmit(false);
      } else {
        cancelHandler();
      }
    }
  }, [isEditMode]);

  return (
    <Wrapper>
      <ProfileInfoContainer>
        <FormInput
          name={profileSchema.firstName}
          control={control}
          label={textTranslation('account.firstName')}
          error={errors[profileSchema.firstName]}
          fill
          isLocked={!isEditMode}
        />
        <FormInput
          name={profileSchema.lastName}
          control={control}
          label={textTranslation('account.lastName')}
          error={errors[profileSchema.lastName]}
          fill
          isLocked={!isEditMode}
        />
        <FormDatePicker
          name={profileSchema.birthDate}
          control={control}
          fill
          label={textTranslation('account.birthDate')}
          title={textTranslation('account.birthDate')}
          error={errors[profileSchema.birthDate]}
          dateSelectOptions={[
            textTranslation('global.day'),
            textTranslation('global.month'),
            textTranslation('global.year'),
          ]}
          isLocked={!isEditMode}
          selectText={textTranslation('selectDate')}
          closeBtnText={textTranslation('cancel')}
          confirmBtnText={textTranslation('confirm')}
          renderEndIcon={() => <ThemedIcon icon={CalendarIcon} palette="primary" strokeColor="hue300" />}
          onSelect={handleDateSelection}
          isOptional={!getValues('birthDate')}
        />
        <FormSelectionPopup
          name={profileSchema.citizenship}
          control={control}
          error={errors[profileSchema.citizenship]?.value}
          itemsList={countriesNationalityAsDropdown}
          label={textTranslation('account.citizenship')}
          title={textTranslation('account.citizenship')}
          searchText={textTranslation('account.search')}
          renderEndIcon={() => <ThemedIcon icon={Globe04} palette="primary" strokeColor="hue300" />}
          isLocked={!isEditMode}
          fill
          closeButtonText={textTranslation('cancel')}
          selectButtonText={textTranslation('select')}
          isOptional={!getValues('citizenship')?.value}
        />
        <FullRowWrapper>
          <InputPhoneNumber
            name={profileSchema.phoneNumber}
            control={control}
            fill
            label={textTranslation('account.phoneNumber')}
            title={textTranslation('account.phoneNumberPrefix')}
            searchText={textTranslation('account.search')}
            itemList={countriesPhoneNumberPrefixesAsDropdown}
            error={errors[profileSchema.phoneNumber]}
            isLocked={!isEditMode}
            selectedItem={getValues(profileSchema.phoneNumberPrefix)}
            handleSelectedItem={handlePhonePrefixSelection}
            closeButtonText={textTranslation('cancel')}
            selectButtonText={textTranslation('select')}
            defaultItem={defaultPhoneNumberPrefixCountry}
            maxLength={16}
          />
        </FullRowWrapper>
      </ProfileInfoContainer>
      {isEditMode && (
        <ButtonWrapper>
          <Button
            text={textTranslation('account.saveChanges')}
            fill
            onClick={() => onSubmit(getValues())}
            disabled={!isDirty || !isValid}
            loading={isLoading}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

import { mapIsoStringToDate } from '@metaswiss/lib';
import { ArrowLeftSmIcon, Chip, Popup, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../../api/msApi';
import { CaseStatusEnum } from '../../../enums/caseStatus.enum';
import { ApiResource } from '../../../enums/resource.enum';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../shared/helpers/getQueryKey.helper';
import { statusTranslationMapper } from '../../../shared/mappers/statusTranslation.mapper';

import { getCardChip } from './mappers/getCardChip';
import {
  ContentWrapper,
  Header,
  LeftContainer,
  ListItem,
  ListWrapper,
  LogoContainer,
  StartHeaderWrapper,
  Wrapper,
} from './styles/casePopup.styles';
import { CasePopupProps } from './types';
import { ChangesCase } from './variants/changes-case/ChangesCase';
import { ReviewCase } from './variants/review-case/ReviewCase';
import { UploadMessage } from './variants/upload-message/UploadMessage';

export const CasePopup: FC<CasePopupProps> = ({ isOpen, close, id, setIsUploadDocument }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean | undefined>(undefined);

  const renderIcon = () => <ThemedIcon strokeColor={'hue700'} icon={ArrowLeftSmIcon} />;
  const { data: caseDetails } = useQuery({
    queryKey: getQueryKey(ApiResource.CASE, id),
    queryFn: () => api.case.getCaseDetails(id!),
    enabled: !!id,
  });

  const statusChip = useMemo(
    () => (caseDetails ? getCardChip(caseDetails?.status as CaseStatusEnum) : undefined),
    [caseDetails]
  );

  const onCloseHandler = () => {
    setIsUploadDocument?.(undefined);
    setIsUploadSuccess?.(undefined);
    close();
  };

  const popupHeight = useMemo(
    () => (isUploadSuccess === undefined ? '39.5rem' : isUploadSuccess ? '27.25rem' : '28.75rem'),
    [isUploadSuccess]
  );

  return (
    <Popup
      isActive={isOpen}
      onOutsideClick={isUploadSuccess ? () => undefined : close}
      height={popupHeight}
      maxWidth={isUploadSuccess === undefined ? '55rem' : '35rem'}
      fill={true}
    >
      {typeof isUploadSuccess !== 'undefined' ? (
        <UploadMessage
          isUploadSuccess={isUploadSuccess}
          close={onCloseHandler}
          setIsUploadSuccess={setIsUploadSuccess}
        />
      ) : (
        caseDetails && (
          <Wrapper>
            <LeftContainer>
              <Header>
                <StartHeaderWrapper>
                  <LogoContainer onClick={close}>{renderIcon()}</LogoContainer>
                  <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
                    {textTranslation('cases.details')}
                  </Text>
                </StartHeaderWrapper>
                {statusChip && (
                  <Chip label={statusTranslationMapper(caseDetails.status, textTranslation)} status={statusChip} />
                )}
              </Header>
              <ContentWrapper>
                <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
                  {caseDetails.name}
                </Text>
                <Text color={theme.v2.text.bodyPrimary}>{mapIsoStringToDate({ date: caseDetails.createdAt })}</Text>
                <Text color={theme.v2.text.bodyPrimary}>{caseDetails.description}</Text>
                {caseDetails.requestChanges.length > 0 && (
                  <>
                    <Text fontWeight={'semi'} color={theme.v2.text.bodyPrimary}>{`${textTranslation(
                      'cases.requestedChanges'
                    )}:`}</Text>
                    <ListWrapper>
                      {caseDetails.requestChanges.map((change) => {
                        return (
                          <ListItem>
                            <Text>{change.message}</Text>
                          </ListItem>
                        );
                      })}
                    </ListWrapper>
                  </>
                )}
              </ContentWrapper>
            </LeftContainer>
            {caseDetails.status === CaseStatusEnum.OPENED && (
              <ChangesCase
                caseDetails={caseDetails}
                setIsUploadSuccess={setIsUploadSuccess}
                setIsUploadDownload={setIsUploadDocument}
              />
            )}
            {caseDetails.status === CaseStatusEnum.CHANGES_REQUESTED && (
              <ChangesCase
                caseDetails={caseDetails}
                setIsUploadSuccess={setIsUploadSuccess}
                setIsUploadDownload={setIsUploadDocument}
              />
            )}
            {caseDetails.status === CaseStatusEnum.APPROVED && <ReviewCase caseDetails={caseDetails} />}
            {caseDetails.status === CaseStatusEnum.PENDING && <ReviewCase caseDetails={caseDetails} />}
          </Wrapper>
        )
      )}
    </Popup>
  );
};

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { CheckCodeRequest } from '../models/CheckCodeRequest';
import type { CheckRestPasswordTokenRequest } from '../models/CheckRestPasswordTokenRequest';
import type { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import type { FullRegistrationCorporateUserRequest } from '../models/FullRegistrationCorporateUserRequest';
import type { FullRegistrationPrivateUserRequestV4 } from '../models/FullRegistrationPrivateUserRequestV4';
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginResponse } from '../models/LoginResponse';
import type { QuickRegistrationCorporateUserRequestV3 } from '../models/QuickRegistrationCorporateUserRequestV3';
import type { QuickRegistrationPrivateUserRequestV3 } from '../models/QuickRegistrationPrivateUserRequestV3';
import type { RefreshTokenRequest } from '../models/RefreshTokenRequest';
import type { RefreshTokenResponse } from '../models/RefreshTokenResponse';
import type { RegisterResponse } from '../models/RegisterResponse';
import type { ResendEmailRequest } from '../models/ResendEmailRequest';
import type { ResetPasswordWithCodeRequest } from '../models/ResetPasswordWithCodeRequest';
import type { ResetPasswordWithTokenRequest } from '../models/ResetPasswordWithTokenRequest';
import type { ThirdPartyRegistrationRequest } from '../models/ThirdPartyRegistrationRequest';
import type { UpdatePasswordFirstTimeRequest } from '../models/UpdatePasswordFirstTimeRequest';
import type { UserResponse } from '../models/UserResponse';
import type { VerifyAccountRequest } from '../models/VerifyAccountRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any Full register private user
   * @throws ApiError
   */
  public fullRegistrationPrivateUser(
    requestBody: FullRegistrationPrivateUserRequestV4,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/private/full-signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Full register corporate user
   * @throws ApiError
   */
  public fullRegistrationCorporateUser(
    requestBody: FullRegistrationCorporateUserRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/corporate/full-signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Reset password with invitation token
   * @throws ApiError
   */
  public resetPasswordWithToken(
    requestBody: ResetPasswordWithTokenRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/reset-password-with-token',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Check validity of reset password token
   * @throws ApiError
   */
  public checkResetPasswordWithToken(
    requestBody: CheckRestPasswordTokenRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/check-reset-password-token',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns RegisterResponse Third party registration
   * @throws ApiError
   */
  public thirdPartyRegistration(
    requestBody: ThirdPartyRegistrationRequest,
  ): CancelablePromise<RegisterResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/third-party-signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns LoginResponse
   * @throws ApiError
   */
  public login(
    requestBody: LoginRequest,
  ): CancelablePromise<LoginResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        404: `Wrong credentials`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public logout(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/logout',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Change password
   * @throws ApiError
   */
  public deleteUser(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v5/auth/delete-user',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public verifyUserAccount(
    requestBody: VerifyAccountRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/verify-account',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param resendToken
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public resendEmail(
    resendToken: string,
    requestBody: ResendEmailRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/resend-email',
      query: {
        'resendToken': resendToken,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns RefreshTokenResponse Update access token
   * @throws ApiError
   */
  public updateAccessToken(
    requestBody: RefreshTokenRequest,
  ): CancelablePromise<RefreshTokenResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/refresh',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param userId
   * @param requestBody
   * @returns any Updating password after initial third party registration
   * @throws ApiError
   */
  public updatePassword(
    userId: string,
    requestBody: UpdatePasswordFirstTimeRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/update-password-first-time',
      query: {
        'userId': userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Change password
   * @throws ApiError
   */
  public changePassword(
    requestBody: ChangePasswordRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/change-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns UserResponse Simple user data
   * @throws ApiError
   */
  public getMe(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/auth/me',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Forgot password
   * @throws ApiError
   */
  public forgotPassword(
    requestBody: ForgotPasswordRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/forgot-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Reset password with six digit code
   * @throws ApiError
   */
  public resetPasswordWithCode(
    requestBody: ResetPasswordWithCodeRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/reset-password-with-code',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Check 6 digit code
   * @throws ApiError
   */
  public checkCode(
    requestBody: CheckCodeRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/check-code',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns RegisterResponse Register private user
   * @throws ApiError
   */
  public quickRegistrationPrivateUser(
    requestBody: QuickRegistrationPrivateUserRequestV3,
  ): CancelablePromise<RegisterResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/private/quick-signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns RegisterResponse Register corporate user
   * @throws ApiError
   */
  public quickRegistrationCorporateUser(
    requestBody: QuickRegistrationCorporateUserRequestV3,
  ): CancelablePromise<RegisterResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/auth/corporate/quick-signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

}

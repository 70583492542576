import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileEmptyIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="23" height="27" viewBox="0 0 23 27" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6941 0.951172H4.42588C2.69192 0.951172 1.28627 2.35682 1.28625 4.09078L1.28613 22.9284C1.28612 24.6624 2.69177 26.0681 4.42574 26.0681L18.5541 26.0681C20.288 26.0681 21.6937 24.6625 21.6937 22.9286L21.6941 2.95121C21.6941 1.84663 20.7987 0.951172 19.6941 0.951172Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

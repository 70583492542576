import { AssetResponse, UpdateCorporateUserRequest } from '@metaswiss/api';
import { mapDateToIsoString } from '@metaswiss/lib';
import {
  Button,
  CalendarIcon,
  FormDatePicker,
  FormDropdown,
  FormInput,
  FormSelectionPopup,
  InputPhoneNumber,
  LuggageIcon,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { ItemProps } from '@metaswiss/ui-kit/src/components/molecules/selection-popup/types';
import { QueryObserverResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import {
  Control,
  DeepRequired,
  FieldError,
  FieldErrorsImpl,
  GlobalError,
  KeepStateOptions,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';

import { api } from '../../../../../api/msApi.ts';
import { AssignedUserAssetEnum } from '../../../../../enums/assignedUserAssetEnum.ts';
import { ApiResource } from '../../../../../enums/resource.enum.ts';
import { AppState, useAppState } from '../../../../../global-state/zustand.ts';
import { useBusinesses } from '../../../../../hooks/use-businesses/useBusinesses.ts';
import { useCountries } from '../../../../../hooks/use-countries/useCountries.ts';
import { useLegalForm } from '../../../../../hooks/use-legal-form/useLegalForm.ts';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { swissPhonePrefix } from '../../../../../shared/constants/swissPhonePrefix.ts';
import { defaultUser } from '../../../../../shared/helpers/defaultUser.ts';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper.ts';
import { setFieldValues } from '../../../../../shared/helpers/setFieldValues.helper.ts';
import { parseFormObjectCorporateUser } from '../mappers/corporateUser.mapper.ts';
import { CorporateFormData, profileCorporateSchema } from '../schemas/corporateProfile.schema.ts';
import { ButtonWrapper, ProfileInfoContainer, Wrapper } from '../styles/profile.styles.ts';

type Props = {
  isEditMode: boolean;
  isDirty: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setValue: UseFormSetValue<CorporateFormData>;
  getValues: UseFormGetValues<CorporateFormData>;
  errors: Partial<FieldErrorsImpl<DeepRequired<CorporateFormData>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  };
  control: Control<CorporateFormData>;
  isValid: boolean;
  handleSubmit: UseFormHandleSubmit<CorporateFormData>;
  cancelHandler: () => void;
  refetchImage: () => Promise<QueryObserverResult>;
  isPictureUpdated: boolean;
  reset: (data?: Partial<CorporateFormData>, keepStateOptions?: KeepStateOptions) => void;
  setUnassignedUrlId: (value: ((prevState: string | undefined) => string | undefined) | string | undefined) => void;
};

export const CorporateUserProfile: FC<Props> = ({
  isEditMode,
  setIsEditMode,
  setValue,
  getValues,
  errors,
  isDirty,
  isValid,
  control,
  cancelHandler,
  refetchImage,
  reset,
  isPictureUpdated,
  setUnassignedUrlId,
}) => {
  const { textTranslation } = useTextTranslation();
  const { countriesPhoneNumberPrefixesAsDropdown, selectedPhoneNumberPrefix } = useCountries();

  const { businesses, findBusiness } = useBusinesses();
  const { legalForms, findLegalForm } = useLegalForm();

  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const queryClient = useQueryClient();

  const [flagSubmit, setFlagSubmit] = useState<boolean>(false);

  const { mutate, isLoading } = useMutation({
    mutationKey: getQueryKey(ApiResource.CORPORATE_USER, user.id),
    mutationFn: (data: CorporateFormData) => {
      const updateData: Partial<UpdateCorporateUserRequest> = {
        ceo: data.ceo,
        owner: data.owner,
        legalFormId: data.legalForm[0].id,
        businessFocusId: data.businessFocus.value,
        phoneNumber: data.phoneNumber,
        phoneNumberPrefix: data.phoneNumberPrefix.meta,
        dateFounded: mapDateToIsoString(data.dateFounded),
        picture: isPictureUpdated ? data.picture : undefined,
      };
      return api.users.updateCorporateDetails(user.id, updateData as UpdateCorporateUserRequest);
    },
    onSuccess: async (response) => {
      setIsEditMode(false);
      setUnassignedUrlId(undefined);
      setFieldValues<CorporateFormData>(
        parseFormObjectCorporateUser(
          response,
          findBusiness(response?.corporateUser?.businessFocus),
          findLegalForm(response?.corporateUser?.legalForm),
          selectedPhoneNumberPrefix(response?.phoneNumberPrefix),
          response.files as unknown as AssetResponse[]
        ),
        setValue
      );
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.ASSET, user.id) });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.PICTURE}/${user.id}`),
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.THUMBNAIL}/${user.id}`),
      });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CORPORATE_USER, user.id) });
      await refetchImage();
      reset(
        parseFormObjectCorporateUser(
          response,
          findBusiness(response?.corporateUser?.businessFocus),
          findLegalForm(response?.corporateUser?.legalForm),
          selectedPhoneNumberPrefix(response?.phoneNumberPrefix),
          response.files as unknown as AssetResponse[]
        )
      );
    },
  });

  useEffect(() => {
    if (!getValues(profileCorporateSchema.phoneNumberPrefix)?.value && isEditMode) {
      const swiss = countriesPhoneNumberPrefixesAsDropdown.find((item: ItemProps) => item.meta === swissPhonePrefix);
      if (swiss) {
        setValue(profileCorporateSchema.phoneNumberPrefix, swiss);
      }
    }
  }, [countriesPhoneNumberPrefixesAsDropdown, getValues(profileCorporateSchema.phoneNumberPrefix), isEditMode]);

  const handleDateSelection = (date: string) => {
    setValue(profileCorporateSchema.dateFounded, date, { shouldValidate: true, shouldDirty: true });
  };

  const handlePhonePrefixSelection = (item: ItemProps) => {
    setValue(profileCorporateSchema.phoneNumberPrefix, item, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit = (data: CorporateFormData) => {
    setFlagSubmit(true);
    mutate(data);
  };

  useEffect(() => {
    if (!isEditMode) {
      if (flagSubmit) {
        setFlagSubmit(false);
      } else {
        cancelHandler();
      }
    }
  }, [isEditMode]);

  return (
    <Wrapper>
      <ProfileInfoContainer>
        <FormInput
          name={profileCorporateSchema.ceo}
          control={control}
          label={textTranslation('account.ceo')}
          error={errors[profileCorporateSchema.ceo]}
          fill
          isLocked={!isEditMode}
          alertIconShown={!getValues(profileCorporateSchema.ceo)}
        />
        <FormInput
          name={profileCorporateSchema.owner}
          control={control}
          label={textTranslation('account.owner')}
          error={errors[profileCorporateSchema.owner]}
          fill
          isLocked={!isEditMode}
          alertIconShown={!getValues(profileCorporateSchema.owner)}
        />
        <FormDatePicker
          name={profileCorporateSchema.dateFounded}
          control={control}
          fill
          label={textTranslation('account.dateFounded')}
          title={textTranslation('account.dateFounded')}
          error={errors[profileCorporateSchema.dateFounded]}
          dateSelectOptions={[
            textTranslation('global.day'),
            textTranslation('global.month'),
            textTranslation('global.year'),
          ]}
          isLocked={!isEditMode}
          selectText={textTranslation('selectDate')}
          closeBtnText={textTranslation('cancel')}
          confirmBtnText={textTranslation('confirm')}
          renderEndIcon={() => <ThemedIcon icon={CalendarIcon} palette="primary" strokeColor="hue300" />}
          onSelect={handleDateSelection}
        />
        <FormSelectionPopup
          name={profileCorporateSchema.businessFocus}
          control={control}
          error={errors[profileCorporateSchema.businessFocus]?.value}
          itemsList={businesses}
          label={textTranslation('account.businessFocus')}
          title={textTranslation('account.businessFocus')}
          searchText={textTranslation('account.search')}
          renderEndIcon={() => <ThemedIcon icon={LuggageIcon} palette="primary" strokeColor="hue300" />}
          isLocked={!isEditMode}
          fill
          closeButtonText={textTranslation('cancel')}
          selectButtonText={textTranslation('select')}
        />
        <FormDropdown
          name={profileCorporateSchema.legalForm}
          error={errors[profileCorporateSchema.legalForm] as FieldError}
          control={control}
          options={legalForms}
          alertIconShown={true}
          fill
          isLocked={!isEditMode}
          label={textTranslation('account.legalForm')}
        />
        <InputPhoneNumber
          name={profileCorporateSchema.phoneNumber}
          control={control}
          fill
          label={textTranslation('account.phoneNumber')}
          title={textTranslation('account.phoneNumberPrefix')}
          searchText={textTranslation('account.search')}
          itemList={countriesPhoneNumberPrefixesAsDropdown}
          error={errors[profileCorporateSchema.phoneNumber]}
          isLocked={!isEditMode}
          selectedItem={getValues(profileCorporateSchema.phoneNumberPrefix)}
          handleSelectedItem={handlePhonePrefixSelection}
          closeButtonText={textTranslation('cancel')}
          selectButtonText={textTranslation('select')}
          defaultItem={countriesPhoneNumberPrefixesAsDropdown.find((item: ItemProps) => item.meta === swissPhonePrefix)}
          maxLength={16}
        />
      </ProfileInfoContainer>
      {isEditMode && (
        <ButtonWrapper>
          <Button
            text={textTranslation('account.saveChanges')}
            fill
            onClick={() => onSubmit(getValues())}
            disabled={!isDirty || !isValid}
            loading={isLoading}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

import { CaseStatusEnum } from '../../../../enums/caseStatus.enum';

export const getCardChip = (status: CaseStatusEnum) => {
  switch (status) {
    case CaseStatusEnum.OPENED: {
      return 'primary';
    }
    case CaseStatusEnum.CHANGES_REQUESTED: {
      return 'error';
    }
    case CaseStatusEnum.PENDING: {
      return 'warning';
    }
    case CaseStatusEnum.APPROVED: {
      return 'neutral';
    }
    default:
      throw new Error('Unsupported status');
  }
};

import { CaseResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';
import { CaseCard, FlatList, PageStateContainer } from '@metaswiss/ui-kit';
import { useState } from 'react';

import { api } from '../../../../api/msApi';
import { CasePopup } from '../../../../components/case-popup/case-popup/CasePopup';
import { NumberOfItems } from '../../../../components/number-of-items/NumberOfItems';
import { ApiResource } from '../../../../enums/resource.enum';
import { usePagination } from '../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { statusTranslationMapper } from '../../../../shared/mappers/statusTranslation.mapper';
import { statusThemeMapper } from '../shared/mappers/statusTheme.mapper';

export const CaseHistory = () => {
  const { textTranslation } = useTextTranslation();

  const [selectedCaseId, setSelectedCaseId] = useState<string | undefined>(undefined);

  const { data, isError, lastItemRef, isLoading, isFetchingNextPage, refetch, totalItems } =
    usePagination<CaseResponse>({
      queryKey: getQueryKey(ApiResource.CASE, 'history'),
      queryFn: (offset: number, limit: number) => {
        return api.case.getHistoryCasesByUser(offset, limit);
      },
      enabled: true,
    });

  const isEmpty = !isLoading && data?.length === 0;
  const isErrorState = isError && data?.length !== 0;

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showEmptyState={true}
      isEmptyState={isEmpty}
      showError={true}
      onTryAgain={refetch}
      isError={isErrorState}
      emptyStateContent={'cases.noCasesHistory'}
      emptyStateTitle={'cases.nothingHereYet'}
      textTranslation={textTranslation}
    >
      <FlatList
        data={data ?? []}
        keyExtractor={(item) => item.id}
        isFetching={isFetchingNextPage}
        ref={lastItemRef}
        headerComponent={<NumberOfItems numberOfAssets={totalItems} />}
        renderItem={(item, index) => (
          <CaseCard
            title={`${index + 1}. ` + item.name}
            date={mapIsoStringToDate({ date: item.createdAt })}
            description={item.description}
            chipText={statusTranslationMapper(item.status, textTranslation)}
            status={statusThemeMapper(item.status)}
            onClick={() => setSelectedCaseId(item.id)}
          />
        )}
      />

      <CasePopup isOpen={!!selectedCaseId} close={() => setSelectedCaseId(undefined)} id={selectedCaseId ?? ''} />
    </PageStateContainer>
  );
};

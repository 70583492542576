import { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { DeleteIcon, DocumentIcon } from '../../../iconography';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';
import { DropzoneStatus } from '../upload-file/enums';

import { formatFileSize } from './helpers/formatFileSize';
import { ContentWrapper, DeleteIconContainer, IconWrapper, TextWrapper, UploadedFileStyled } from './styles/file';
import { AddedFileProps } from './types';

export const AddedFile: FC<AddedFileProps> = ({
  $width,
  onRetryClick,
  onDeleteClick,
  onViewDocumentClick,
  isDeleteAvailable,
  status = DropzoneStatus.FAILED,
  name,
  size,
  uploadFailedSubtitle,
  uploadFailedTitle,
  isSubmitLoading,
}) => {
  const theme = useTheme();
  const fileIcon = (status: DropzoneStatus) => {
    switch (status) {
      case DropzoneStatus.INITIAL:
        return <DocumentIcon strokeColor={theme.v2.text.bodySecondary} />;
      case DropzoneStatus.LOADING:
        return <ClipLoader color={theme.v2.icon.primary} />;
      case DropzoneStatus.FAILED:
        return <ClipLoader color={theme.v2.icon.error} />;
      default:
        return <DocumentIcon strokeColor={theme.v2.text.bodySecondary} />;
    }
  };

  return (
    <UploadedFileStyled
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        status === DropzoneStatus.FAILED && onRetryClick((e.target as HTMLDivElement).id);
        status === DropzoneStatus.INITIAL && onViewDocumentClick((e.target as HTMLDivElement).id);
      }}
      $width={$width}
      isDisabled={isSubmitLoading}
      pointer={status !== DropzoneStatus.LOADING}
    >
      <IconWrapper>
        {isSubmitLoading ? (
          <ThemedIcon icon={DocumentIcon} size={'xl'} customStrokeColor={theme.v2.text.bodySecondary} />
        ) : (
          <ThemedIcon icon={() => fileIcon(status)} size={'xl'} />
        )}
      </IconWrapper>
      <ContentWrapper>
        <TextWrapper>
          <Text
            fontWeight={'bold'}
            fontSize={'base'}
            color={status === DropzoneStatus.FAILED ? theme.v2.text.error : theme.v2.text.headingPrimary}
          >
            {status !== DropzoneStatus.FAILED ? name : uploadFailedTitle}
          </Text>
          <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.bodySecondary}>
            {status !== DropzoneStatus.FAILED ? formatFileSize(size) : uploadFailedSubtitle}
          </Text>
        </TextWrapper>
      </ContentWrapper>
      {!isSubmitLoading && (
        <IconWrapper
          $height={'1.5rem'}
          $width={'1.5rem'}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (onDeleteClick) {
              onDeleteClick((e.target as HTMLDivElement).id);
            }
          }}
        >
          {isDeleteAvailable && (
            <DeleteIconContainer>
              <ThemedIcon
                icon={DeleteIcon}
                customStrokeColor={theme.v2.text.onAction}
                customColor={theme.v2.icon.neutral}
              />
            </DeleteIconContainer>
          )}
        </IconWrapper>
      )}
    </UploadedFileStyled>
  );
};

import { ColorPalette, DocumentIcon, FileEmptyIcon, PresentationIcon } from '@metaswiss/ui-kit';
import { FC } from 'react';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { CardOfferingDetailsHolder } from '../../pages/protected/offering/shared/components/card-offering-details-holder/CardOfferingDetailsHolder';

import { Document } from './Document';
import { DocumentsContainer } from './documents.styles';

export type DocumentType = {
  id: string;
  name: string;
  url?: string;
};

type Props = {
  documents: DocumentType[];
  palette?: ColorPalette;
};

const getDocumentIcon = (documentType: string) => {
  switch (documentType) {
    case 'presentation':
      return PresentationIcon;
    case 'white paper':
      return FileEmptyIcon;
    default:
      return DocumentIcon;
  }
};

export const Documents: FC<Props> = ({ documents, palette = 'primary' }) => {
  const { textTranslation } = useTextTranslation();

  if (!Array.isArray(documents)) {
    return <p>No documents available</p>;
  }

  return (
    <CardOfferingDetailsHolder heading={textTranslation('offering.documents')} fitHeight={false}>
      <DocumentsContainer>
        {documents?.map((document: DocumentType) => {
          return (
            <Document
              forceUrl={document.url}
              asset={document}
              key={document.id}
              palette={palette}
              documentIcon={getDocumentIcon(document.name)}
            />
          );
        })}
      </DocumentsContainer>
    </CardOfferingDetailsHolder>
  );
};

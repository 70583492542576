import { routes } from '../../../../../router/routes';
import { NavigationTabProps } from '../../../../../shared/types/tabProps';

export const casesTabs: NavigationTabProps[] = [
  {
    label: 'Active cases',
    route: routes.cases.active.root,
    value: 'active',
    hasInfiniteScroll: true,
  },
  {
    label: 'Case history',
    route: routes.cases.history,
    value: 'history',
    hasInfiniteScroll: true,
  },
];

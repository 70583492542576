import { SuccessFeedback, Text } from '@metaswiss/ui-kit';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { AnimationContainer } from '../../../shared/animations/AnimationContainer';
import { useAuthContext } from '../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig';
import { FeedbackWrapper, SuccessSubTitleContainer } from '../../register.styles';

export const RegistrationSuccess = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();

  const { userEmailRegistrationField, setUserEmailField } = useAuthContext((state) => state);

  useSetAuthConfig(
    {
      wrapperStyle: 'authFinish',
      containerStyle: 'authFinish',
      nextButton: {
        animationDirection: 'login-in',
        onClick: () => {
          setUserEmailField('');
          navigate(routes.login);
        },
        text: 'login.logIn',
      },
    },
    [navigate]
  );

  return (
    <AnimationContainer centerContent={true}>
      <FeedbackWrapper>
        <SuccessFeedback
          imageWidth="19.75rem"
          fill
          title={textTranslation('registration.verifyEmail')}
          subTitleRender={(subtitle) => (
            <SuccessSubTitleContainer>
              <Text color={theme.v2.text.disabled}>{subtitle}</Text>
              <Text color={theme.v2.text.action} fontWeight="bold">
                {userEmailRegistrationField}
              </Text>
            </SuccessSubTitleContainer>
          )}
          subTitle={textTranslation('registration.emailSent')}
        />
      </FeedbackWrapper>
    </AnimationContainer>
  );
};

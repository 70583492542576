import { DropzoneStatus } from '../upload-file';

import { AcceptedFileTypes } from './enums';

export type AddedFileProps = {
  $width: string;
  status?: DropzoneStatus;
  onRetryClick: (id: string) => void;
  onDeleteClick?: (id: string) => void;
  onViewDocumentClick: (id: string) => void;
  isDeleteAvailable: boolean;
  fileId: string;
  name: string;
  size: number;
  uploadFailedTitle: string;
  uploadFailedSubtitle: string;
  isSubmitLoading?: boolean;
};

export type AddedFileContainerProps = {
  $width: string;
  fileId: string;
  name: string;
  disabled?: boolean;
};

export type RejectedFileProps = {
  $width: string;
  onDeleteClick: () => void;
  invalidFileTypeTitle: string;
  invalidFileTypeSubtitle: string;
};

export type Value = {
  status: DropzoneStatus;
  url: string;
  fileId: string;
  name: string;
  size: number;
  file: File;
};

export type UploadedFileMultipleProps = {
  $width?: string;
  $height?: string;
  dropzoneTitle: string;
  dropzoneSubtitle: string;
  uploadFiles: (files: File[]) => void;
  values: Value[];
  onDelete?: (id: string) => void;
  onRetry?: (id: string) => void;
  onViewDocument?: (id: string) => void;
  isDeleteAvailable: boolean;
  disabled: boolean;
  isLoading: boolean;
  isSubmitLoading: boolean;
  uploadFailedTitle: string;
  uploadFailedSubtitle: string;
  invalidFileTypeTitle: string;
  invalidFileTypeSubtitle: string;
  className?: string;
  id: string;
  acceptedFileTypes?: AcceptedFileTypes;
  maxFiles?: number | 'unlimited';
};

export const imageFileTypes = (acceptedFileTypes: AcceptedFileTypes) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/png': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.png'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/jpg': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.jpg'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/jpeg': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.jpeg'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/gif': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.gif'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/svg+xml': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.svg'] : [],
});

export const documentFileTypes = (acceptedFileTypes: AcceptedFileTypes) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/pdf': acceptedFileTypes !== AcceptedFileTypes.IMAGES ? ['.pdf'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.docx'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/msword': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.doc'] : [],
});

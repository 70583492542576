import { FC } from 'react';

import { DocumentIcon } from '../../../iconography';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';

import { ContentWrapper, IconWrapper, TextWrapper, UploadedFileStyled } from './styles/file';
import { AddedFileContainerProps } from './types';

export const AddedFileContainer: FC<AddedFileContainerProps> = ({ $width, name, disabled = true }) => {
  return (
    <UploadedFileStyled $width={$width} isDisabled={disabled}>
      <IconWrapper>
        <ThemedIcon icon={DocumentIcon} strokeColor={disabled ? 'hue300' : 'hue700'} size={'large'} />
      </IconWrapper>
      <ContentWrapper>
        <TextWrapper>
          <Text fontWeight={'bold'} fontSize={'base'} palette={'neutrals'} textColor={'hue300'}>
            {name}
          </Text>
        </TextWrapper>
      </ContentWrapper>
    </UploadedFileStyled>
  );
};

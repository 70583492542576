import { FC } from 'react';

import { IconProps } from './iconProps';

export const PresentationIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="27" height="25" viewBox="0 0 27 25" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.21089 11.725V10.1552M13.4901 11.725V8.58539M19.7694 11.725V5.44576M10.3505 18.0043L7.99579 24.2835M19.4994 24.1963L16.7243 18.0912M4.07126 18.0043C2.3373 18.0043 0.931641 16.5986 0.931641 14.8646V3.87595C0.931641 2.14199 2.3373 0.736328 4.07126 0.736328H22.909C24.643 0.736328 26.0486 2.14199 26.0486 3.87595V14.8646C26.0486 16.5986 24.643 18.0043 22.909 18.0043H4.07126Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

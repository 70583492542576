import styled from 'styled-components';

export const SuccessSubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepTextContainer = styled.div`
  margin-bottom: 1rem;
`;

export const FeedbackWrapper = styled.div`
  margin: auto 0;
`;

import { NewsletterResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';
import { Text, TextLink } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../router/routes';
import { getFormattedLanguageName } from '../../../../../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';
import { NewsletterItem } from '../newsletter-item/NewsletterItem';

import { NewsletterContainer, NewsletterContent, NewsletterHeader } from './newsletter.styles';

export const Newsletter = () => {
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const navigate = useNavigate();

  const { data: newsletters } = useQuery({
    queryKey: getQueryKey(ApiResource.NEWSLETTER, currentLanguage),
    queryFn: () => api.contentful.getNewsletters(getFormattedLanguageName(currentLanguage), 0, 3, 0),
  });

  if (!newsletters || newsletters.items.length === 0) {
    return null;
  }

  return (
    <NewsletterContainer>
      <NewsletterHeader>
        <Text fontSize={'lg'} fontWeight={'bold'} lineHeight="h4" color={theme.v2.text.headingPrimary}>
          {textTranslation('home.newsletter')}
        </Text>
        <TextLink onClick={() => navigate(routes.home.newsletter)}>{textTranslation('global.viewAll')}</TextLink>
      </NewsletterHeader>
      <NewsletterContent
        disableStateEffects
        override={{
          borderRadius: theme.v2.componentsRadius.cards.default,
          backgroundColor: theme.v2.surface.primary,
          borderColor: theme.v2.border.primary,
        }}
      >
        {newsletters.items?.map((newsletter: NewsletterResponse, index: number) => {
          if (index < 3) {
            return (
              <NewsletterItem
                key={`${newsletter.title}-${newsletter.createdAt}`}
                title={newsletter.title}
                description={newsletter.description}
                date={mapIsoStringToDate({ date: newsletter.publishDate, splitChar: '.', endWithChar: true })}
                buttonText={textTranslation('home.readMore')}
                onClick={() => window.open(newsletter.document.url, '_blank')}
                image={newsletter.image.url}
              />
            );
          }
        })}
      </NewsletterContent>
    </NewsletterContainer>
  );
};

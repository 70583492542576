import { CompanyDocumentResponse } from '@metaswiss/api';
import { PageStateContainer } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC, useMemo } from 'react';

import { api } from '../../../../api/msApi';
import { Languages } from '../../../../enums/language';
import { ApiResource } from '../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getFormattedLanguageName } from '../../../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { tenantConfig } from '../../../../tenantConfig';

import { AboutUs } from './components/about-us/AboutUs';
import { BuyCardsContainer } from './components/buy-cards-container/BuyCardsContainer';
import { Documents } from './components/documents/Documents';
import { Header } from './components/header/Header';
import { HomeBanner } from './components/home-banner/HomeBanner';
import { Newsletter } from './components/newsletter/Newsletter';
import {
  DesktopContainer,
  LeftContainer,
  LeftContainerContent,
  RightContainer,
  TabletContainer,
} from './dashboard.styles';

export const Dashboard: FC = () => {
  const { textTranslation, currentLanguage } = useTextTranslation();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.COMPANY_DETAILS),
    queryFn: () => api.contentful.getCompanyDetails(getFormattedLanguageName(currentLanguage)),
  });

  const { desktopImg, tabletImg } = useMemo(
    () =>
      currentLanguage === Languages.ENGLISH
        ? { desktopImg: tenantConfig.enDesktopHomeBanner, tabletImg: tenantConfig.enTabletHomeBanner }
        : { desktopImg: tenantConfig.deDesktopHomeBanner, tabletImg: tenantConfig.deTabletHomeBanner },
    [currentLanguage]
  );

  const companyDocuments = useMemo(() => {
    return data ? (data as unknown as CompanyDocumentResponse[]) : [];
  }, [data]);

  return (
    <PageStateContainer
      isLoading={isLoading}
      isError={isError}
      showError={true}
      showLoading={true}
      textTranslation={textTranslation}
      showEmptyState={false}
      onTryAgain={refetch}
    >
      <DesktopContainer>
        <LeftContainer>
          <LeftContainerContent>
            <Header />
            <BuyCardsContainer />
            <AboutUs />
            <Newsletter />
          </LeftContainerContent>
        </LeftContainer>
        <RightContainer>
          <HomeBanner desktopImg={desktopImg} tabletImg={tabletImg} />
          <Documents documents={companyDocuments} />
        </RightContainer>
      </DesktopContainer>
      <TabletContainer>
        <HomeBanner desktopImg={desktopImg} tabletImg={tabletImg} />
        <Header />
        <BuyCardsContainer />
        <AboutUs />
        <Documents documents={companyDocuments} />
        <Newsletter />
      </TabletContainer>
    </PageStateContainer>
  );
};

import { FC } from 'react';

import { DeleteIcon, InvalidFileTypeIcon } from '../../../iconography';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';

import {
  ContentWrapper,
  DeleteIconContainer,
  UploadedRejectedFileStyled,
  TextRejectedWrapper,
  IconRejectedWrapper,
  IconRejectedCloseWrapper,
} from './styles/file';
import { RejectedFileProps } from './types';

export const RejectedFile: FC<RejectedFileProps> = ({
  $width,
  onDeleteClick,
  invalidFileTypeSubtitle,
  invalidFileTypeTitle,
}) => {
  return (
    <UploadedRejectedFileStyled $width={$width}>
      <IconRejectedWrapper>
        <ThemedIcon icon={InvalidFileTypeIcon} palette={'error'} strokeColor={'hue300'} size={'full'} />
      </IconRejectedWrapper>
      <ContentWrapper>
        <TextRejectedWrapper>
          <Text fontWeight={'bold'} fontSize={'base'} textColor={'hue300'} palette={'error'}>
            {invalidFileTypeTitle}
          </Text>
          <Text fontSize={'sm'} lineHeight={'medium'}>
            {invalidFileTypeSubtitle}
          </Text>
        </TextRejectedWrapper>
      </ContentWrapper>
      <IconRejectedCloseWrapper $width={'1.5rem'} $height={'1.5rem'} onClick={onDeleteClick}>
        <DeleteIconContainer>
          <ThemedIcon icon={DeleteIcon} color={'hue300'} />
        </DeleteIconContainer>
      </IconRejectedCloseWrapper>
    </UploadedRejectedFileStyled>
  );
};

import { Button, ErrorFeedback, SuccessFeedback } from '@metaswiss/ui-kit';
import { FC } from 'react';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { ItemsWrapper, UploadMessageWrapper } from '../../styles/casePopup.styles';

type Props = {
  isUploadSuccess?: boolean;
  setIsUploadSuccess?: (isUploadSuccess?: boolean) => void;
  close: () => void;
};

export const UploadMessage: FC<Props> = ({ isUploadSuccess, setIsUploadSuccess, close }) => {
  const { textTranslation } = useTextTranslation();

  return (
    <UploadMessageWrapper>
      <ItemsWrapper>
        {isUploadSuccess ? (
          <SuccessFeedback
            title={textTranslation('cases.successTitle')}
            subTitle={textTranslation('cases.successSubtitle')}
            imageWidth={'16.5rem'}
            fill
          />
        ) : (
          <ErrorFeedback
            title={textTranslation('cases.errorTitle')}
            subTitle={textTranslation('cases.errorSubtitle')}
            imageWidth={'15rem'}
            fill
          />
        )}
        <Button
          type={'submit'}
          onClick={isUploadSuccess ? () => close() : () => setIsUploadSuccess?.(undefined)}
          fill
          text={isUploadSuccess ? textTranslation('cases.successButton') : textTranslation('cases.errorButton')}
        />
      </ItemsWrapper>
    </UploadMessageWrapper>
  );
};

import { CaseDetailsResponse } from '@metaswiss/api';
import { AddedFileContainer, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { DocumentsWrapper, ReviewCasesWrapper } from '../../styles/casePopup.styles';

type Props = {
  caseDetails: CaseDetailsResponse;
};

export const ReviewCase: FC<Props> = ({ caseDetails }) => {
  const { textTranslation } = useTextTranslation();

  return (
    <ReviewCasesWrapper>
      <Text fontWeight={'bold'}>{textTranslation('cases.submittedDocuments')}</Text>
      <DocumentsWrapper>
        {caseDetails?.assets?.map((asset) => (
          <AddedFileContainer $width={'100%'} fileId={asset.id} name={asset.fileName} />
        ))}
      </DocumentsWrapper>
    </ReviewCasesWrapper>
  );
};

import { BaseCard, RemSize } from '@metaswiss/ui-kit';
import { StyledButton } from '@metaswiss/ui-kit/src/components/atoms/button/styles/styledButton';
import styled from 'styled-components';

export const StyledNewsletterItemCard = styled(BaseCard)<{ $width?: string; $height?: string }>`
  display: flex;
  width: ${({ $width }) => ($width ? $width : '33.333%')};
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  height: ${({ $height }) => $height || 'fit-content'};

  &:hover ${StyledButton} {
    background-color: ${({ theme }) => theme.v2.surface.tertiary};
    border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  }

  &:active ${StyledButton} {
    background-color: ${({ theme }) => theme.v2.surface.tertiary};
    border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  }
`;

export const StyledImage = styled.img<{ $imgHeight?: RemSize }>`
  width: 100%;
  height: ${({ $imgHeight }) => ($imgHeight ? $imgHeight : '5rem')};
  object-fit: cover;
`;

export const DescriptionTextContainer = styled.div`
  padding-bottom: 1rem;
  padding: 0 1rem;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  height: 2.25rem;
`;

export const NewsletterItemContent = styled.div<{ $contentHeight?: RemSize }>`
  display: flex;
  flex-direction: column;
  height: ${({ $contentHeight }) => ($contentHeight ? $contentHeight : '100%')};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 1rem;
`;

export const ButtonWrapper = styled.div`
  margin: 1rem;
`;

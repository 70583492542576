export enum ApiResource {
  ABOUT_US = 'about-us',
  USER = 'user',
  PRIVATE_USER = 'privateUser',
  CORPORATE_USER = 'corporateUser',
  ASSET = 'asset',
  ASSIGNED_URL = 'assignedUrl',
  UNASSIGNED_URL = 'unassignedUrl',
  CASE = 'case',
  CASE_CONVERSATION = 'caseConversation',
  SALES_INFORMATION = 'salesInformation',
  CURRENCY = 'currency',
  BUSINESS_FOCUS = 'businessFocus',
  LEGAL_FOCUS = 'legalFocus',
  COUNTRY = 'country',
  NFT_COLLECTION = 'nftCollection',
  NFT_SINGLE_COLLECTION = 'nftSingleCollection',
  NFT = 'nft',
  SHARE = 'share',
  SHARES = 'shares',
  CASES = 'cases',
  BOND = 'bond',
  TRANSACTIONS = 'transactions',
  SHARE_TRANSACTION = 'shareTransaction',
  BOND_TRANSACTION = 'bondTransaction',
  NFT_TRANSACTION = 'nftTransaction',
  SHARE_TRANSACTION_ASSETS = 'shareTransactionAssets',
  BOND_TRANSACTION_ASSETS = 'bondTransactionAssets',
  NFT_TRANSACTION_ASSETS = 'nftransactionAssets',
  NFT_COLLECTION_BANNER = 'nftCollectionBanner',
  NFT_COLLECTION_LOGO = 'nftCollectionLogo',
  SHARE_STATISTICS = 'shareStatistics',
  SHARE_ASSETS = 'shareAssets',
  NFT_COLLECTION_STATISTICS = 'nftCollectionStatistics',
  NOTIFICATIONS = 'NOTIFICATIONS',
  COLLECTIBLES = 'collectibles',
  NFT_PRODUCT_LOGO = 'nftProductLogo',
  SHARE_AUDIT = 'shareAudit',
  NEWSLETTER = 'newsletter',
  PORTFOLIO_NFTS = 'portfolioNfts',
  PORTFOLIO_SHARES = 'portfolioShares',
  PORTFOLIO_BONDS = 'portfolioBonds',
  USER_INVESTED_PORTFOLIO = 'userInvestedPortfolio',
  BOND_PORTFOLIO_STATISTICS = 'bondPortfolioStatistics',
  SHARE_PORTFOLIO_STATISTICS = 'sharePortfolioStatistics',
  NFT_PORTFOLIO_STATISTICS = 'nftPortfolioStatistics',
  PORTFOLIO = 'portfolio',
  COUPONS = 'coupons',
  SHARE_BENEFITS = 'shareBenefits',
  BOND_BENEFITS = 'bondBenefits',
  SHARE_LISTING = 'shareListing',
  SHARE_FULL = 'shareFull',
  COMPANY_DETAILS = 'companyDetails',
  VAT = 'vat',
  DELIVERY_ADDRESS = 'deliveryAddress',
  BOND_INVESTORS = 'bondInvestors',
  USER_RESPONSE = 'userResponse',
  TENANT_USER = 'tenantUser',
}

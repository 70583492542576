import { zodResolver } from '@hookform/resolvers/zod';
import { CaseDetailsResponse } from '@metaswiss/api';
import { AcceptedFileTypes, Button, DropzoneStatus, UploadFileMultiple, Value } from '@metaswiss/ui-kit';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { api } from '../../../../../api/msApi';
import { ApiResource } from '../../../../../enums/resource.enum.ts';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper.ts';
import { ButtonWrapper, ChangesCasesWrapper, OpenedContainer, UploadFileWrapper } from '../../styles/casePopup.styles';

import { FormData, schema, uploadFilesSchema } from './schema';

type Props = {
  caseDetails: CaseDetailsResponse;
  setIsUploadSuccess?: (isUploadSuccess: boolean | undefined) => void;
  setIsUploadDownload?: (isUploadSuccess: boolean | undefined) => void;
};

export const ChangesCase: FC<Props> = ({ caseDetails, setIsUploadSuccess, setIsUploadDownload }) => {
  const { textTranslation } = useTextTranslation();
  const queryClient = useQueryClient();

  const {
    setValue,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (file: File) => {
      return api.assets.uploadUnassignedFile({ file: file, fileType: file.type });
    },
    onMutate: (variables) => {
      const uploadedFiles = getValues().documents;

      const upload = !uploadedFiles ? [] : uploadedFiles;

      upload.push({
        status: DropzoneStatus.LOADING,
        fileId: '',
        url: '',
        name: variables.name,
        size: variables.size,
        file: variables,
      });

      setValue(uploadFilesSchema.documents, upload, { shouldValidate: true, shouldDirty: true });
    },
    onSuccess: async (response, variables) => {
      const uploadedFiles = getValues().documents as Value[];

      const foundFile = uploadedFiles.findIndex((file) => file.file === variables);

      const assetData = await api.assets.getS3SignedUnassignedUrl({ assetId: response.fileId });

      uploadedFiles[foundFile] = {
        status: DropzoneStatus.INITIAL,
        fileId: response.fileId,
        url: assetData.url,
        name: variables.name,
        size: variables.size,
        file: variables as File,
      };

      setValue(uploadFilesSchema.documents, uploadedFiles, { shouldValidate: true, shouldDirty: true });
    },
    onError: (variables: File) => {
      const uploadedFiles = getValues().documents as Value[];

      const foundFile = uploadedFiles.findIndex((file) => file.file === variables);

      uploadedFiles[foundFile] = {
        status: DropzoneStatus.FAILED,
        fileId: (Math.floor(Math.random() * 10000) + 1).toString(),
        url: '',
        name: variables.name,
        size: variables.size,
        file: variables as File,
      };

      setValue(uploadFilesSchema.documents, uploadedFiles, { shouldValidate: true, shouldDirty: true });
    },
  });

  const { mutate: submitDocs, isLoading: isSubmitLoading } = useMutation({
    mutationFn: (files: string[]) => {
      return api.case.addCaseDocuments(caseDetails.id, {
        documentsIds: files,
        message: '',
      });
    },
    onSuccess: async () => {
      setIsUploadSuccess?.(true);
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'active') });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, caseDetails.id) });
    },
    onError: () => {
      setIsUploadSuccess?.(false);
    },
  });

  const onUploadFiles = (files: File[]) => {
    files?.map((file) => {
      mutate(file);
    });
  };

  const onRetry = (id: string) => {
    const uploadedFiles = getValues().documents;

    const foundFile = uploadedFiles.find((uploaded) => uploaded.fileId === id);

    mutate(foundFile?.file as File);
  };

  const onView = (id: string) => {
    const uploadedFiles = getValues().documents;

    const foundFile = uploadedFiles.find((uploaded) => uploaded.fileId === id);

    window.open(foundFile?.url, '_blank', 'noopener,noreferrer');
  };

  const onDelete = (id: string) => {
    const uploadedFiles = getValues().documents;

    const fileIndex = uploadedFiles.findIndex((uploaded) => uploaded.fileId === id);

    uploadedFiles.splice(fileIndex, 1);

    setValue(uploadFilesSchema.documents, uploadedFiles, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit = () => {
    setIsUploadDownload?.(true);
    const uploadedFiles = getValues().documents;
    const uploadedSuccessfully = uploadedFiles
      .filter((file) => file.status === DropzoneStatus.INITIAL)
      .map((file) => file.fileId);
    submitDocs(uploadedSuccessfully);
  };

  return (
    <ChangesCasesWrapper>
      <OpenedContainer>
        <UploadFileWrapper>
          <UploadFileMultiple
            dropzoneTitle={textTranslation('cases.dropDocumentTitle')}
            dropzoneSubtitle={textTranslation('cases.dropDocumentSubtitle')}
            uploadFiles={onUploadFiles}
            values={getValues().documents as Value[]}
            onRetry={onRetry}
            onViewDocument={onView}
            onDelete={onDelete}
            isDeleteAvailable={true}
            disabled={false}
            isLoading={isLoading}
            isSubmitLoading={isSubmitLoading}
            uploadFailedTitle={textTranslation('cases.uploadFailedTitle')}
            uploadFailedSubtitle={textTranslation('cases.uploadFailedSubtitle')}
            invalidFileTypeTitle={textTranslation('cases.invalidFileTitle')}
            invalidFileTypeSubtitle={textTranslation('cases.invalidFileSubtitle')}
            acceptedFileTypes={AcceptedFileTypes.IMAGES}
            id={uploadFilesSchema.documents}
            $width={'100%'}
            $height={'12.5rem'}
          />
        </UploadFileWrapper>
        <ButtonWrapper $isDisabled={!isValid || getValues().documents?.length === 0 || isLoading}>
          <Button
            disabled={!isValid || getValues().documents?.length === 0 || isLoading}
            onClick={handleSubmit(onSubmit)}
            type={'submit'}
            fill
            text={textTranslation('global.submitDocuments')}
            loading={isSubmitLoading}
          />
        </ButtonWrapper>
      </OpenedContainer>
    </ChangesCasesWrapper>
  );
};

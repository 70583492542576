import { TransactionResponseDTO } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';
import {
  DataStatusPage,
  FlatList,
  NoResourcesIcon,
  PageStateContainer,
  ThemedIcon,
  TransactionCard,
} from '@metaswiss/ui-kit';
import { TransactionStatus } from '@metaswiss/ui-kit/src/components/molecules/cards/transaction-card/enums.ts';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi.ts';
import { Filters } from '../../../../../components/filters/Filters.tsx';
import { NumberOfItems } from '../../../../../components/number-of-items/NumberOfItems.tsx';
import { FilterType } from '../../../../../enums/filterType.enum.ts';
import { PaymentMethod } from '../../../../../enums/paymentMethod.enum.ts';
import { ProductType } from '../../../../../enums/productType.enum.ts';
import { ApiResource } from '../../../../../enums/resource.enum.ts';
import { AppState, useAppState, useHeaderOptions } from '../../../../../global-state/zustand.ts';
import { usePagination } from '../../../../../hooks/use-pagination/usePagination.ts';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { routes } from '../../../../../router/routes.ts';
import { defaultUser } from '../../../../../shared/helpers/defaultUser.ts';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper.ts';
import { getSingleTransactionRouteByType } from '../../../../../shared/helpers/getSingleTransactionRouteByType.ts';

import { TransactionCardWrapper } from './transactions.styles.ts';

export type FilterItem = {
  id: string;
  label: string;
  value: string;
  param?: string;
};

type QueryType = {
  status?: Array<'PENDING' | 'VALIDATING' | 'ACCEPTED' | 'CANCELLED' | 'FAILED'>;
  type?: Array<'BOND_PAPERS' | 'ACTIONS' | 'NFT'>;
  payment?: Array<'STRIPE' | 'BANK' | 'CRYPTO'>;
};

export const AllTransactions = () => {
  const theme = useTheme();
  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const navigate = useNavigate();

  const { selectedFilters } = useHeaderOptions();
  const { textTranslation } = useTextTranslation();

  const [dataTransactions, setDataTransactions] = useState<boolean>(false);
  const [transactionTotalItems, setTransactionTotalItems] = useState<number>(0);
  const [query, setQuery] = useState<QueryType>({});

  const transactionsFilterOptions = useMemo(
    () => [
      {
        id: '1',
        title: textTranslation('global.type'),
        param: FilterType.TYPE,
        options: [
          { id: '1', label: textTranslation('global.smartShares'), value: ProductType.ACTIONS },
          {
            id: '2',
            label: textTranslation('global.smartBonds'),
            value: ProductType.BOND_PAPERS,
          },
          { id: '3', label: textTranslation('global.smartNfts'), value: ProductType.NFT },
        ],
      },
      {
        id: '2',
        title: textTranslation('global.paymentMethod'),
        param: FilterType.PAYMENT,
        options: [
          { id: '4', label: textTranslation('global.stripe'), value: PaymentMethod.STRIPE },
          { id: '5', label: textTranslation('global.bank'), value: PaymentMethod.BANK },
        ],
      },
      {
        id: '3',
        title: textTranslation('global.status'),
        param: FilterType.STATUS,
        options: [
          {
            id: '6',
            label: textTranslation('global.accepted'),
            value: TransactionStatus.ACCEPTED,
          },
          {
            id: '7',
            label: textTranslation('global.pending'),
            value: TransactionStatus.PENDING,
          },
          {
            id: '8',
            label: textTranslation('global.cancelled'),
            value: TransactionStatus.CANCELLED,
          },
        ],
      },
    ],
    []
  );

  const { data, lastItemRef, isError, fetchNextPage, isLoading, isFetchingNextPage, totalItems } =
    usePagination<TransactionResponseDTO>({
      queryKey: getQueryKey(ApiResource.TRANSACTIONS, {
        userId: user.id,
        status: query.status,
        type: query.type,
        payment: query.payment,
      }),
      queryFn: (offset: number, limit: number) => {
        return api.clientTransaction.getClientTransactions(
          offset,
          limit,
          undefined,
          undefined,
          query.status,
          query.type,
          query.payment
        );
      },
      enabled: true,
    });

  useEffect(() => {
    if (!isLoading) {
      setTransactionTotalItems(totalItems);
    }

    if (data.length) {
      setDataTransactions(true);
    }
  }, [isLoading, totalItems, data]);

  return (
    <>
      <Filters
        isFilterButtonDisplayed={dataTransactions}
        filterOptions={transactionsFilterOptions}
        setQuery={setQuery}
        totalItems={transactionTotalItems}
      />
      <PageStateContainer
        showLoading={true}
        isLoading={isLoading}
        showError={true}
        onTryAgain={async () => await Promise.all([fetchNextPage()])}
        isError={isError}
        showEmptyState={true}
        isEmptyState={!isLoading && !data?.length && !totalItems}
        customEmptyStateComponent={
          <>
            {selectedFilters.length === 0 && (
              <DataStatusPage
                icon={
                  <ThemedIcon
                    icon={() => (
                      <NoResourcesIcon
                        foregroundColor={theme.v2.icon.primary}
                        backgroundColor={theme.v2.surface.informationLight}
                      />
                    )}
                    size={'full'}
                  />
                }
                title={textTranslation('account.noTransactions')}
                content={textTranslation('account.itAppears')}
                buttonText={textTranslation('account.startInvesting')}
                action={() => navigate(routes.offering.root)}
                iconWidth={'11.185rem'}
                iconHeight={'11.875rem'}
              />
            )}

            {selectedFilters.length !== 0 && (
              <DataStatusPage
                title={textTranslation('account.noResultsFound')}
                content={textTranslation('account.noResultsFoundSub')}
              />
            )}
          </>
        }
        textTranslation={textTranslation}
      >
        <FlatList
          data={data || []}
          isFetching={isFetchingNextPage}
          keyExtractor={(item) => item.id}
          ref={lastItemRef}
          numberOfColumnsOnSmallerDevices={2}
          renderItem={(item) => (
            <TransactionCardWrapper>
              <TransactionCard
                transaction={item}
                valueLabel={textTranslation('account.value')}
                quantityLabel={textTranslation('account.quantity')}
                smartLabel={textTranslation('account.smart')}
                createdAt={mapIsoStringToDate({ date: item.createdAt })}
                borderRadius={'xl'}
                borderBox={true}
                onClick={() => navigate(getSingleTransactionRouteByType(item.type as ProductType, item.id))}
              />
            </TransactionCardWrapper>
          )}
          headerComponent={<NumberOfItems numberOfAssets={totalItems} />}
        />
      </PageStateContainer>
    </>
  );
};

import { StyledButton } from '@metaswiss/ui-kit/src/components/atoms/button/styles/styledButton';
import styled, { css } from 'styled-components';

const caseWrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  background: ${({ theme }) => theme.v2.surface.primary};
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const ReviewCasesWrapper = styled.div`
  ${caseWrapperStyles};
  padding: 1.25rem 1rem;
  gap: 1.25rem;
`;

export const ChangesCasesWrapper = styled.div`
  ${caseWrapperStyles}
  padding: 1rem;
`;

export const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1.25rem 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const LogoContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

export const StartHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1rem;
  flex: 1 0 0;
  width: 100%;
  overflow-y: auto;
`;

export const ListWrapper = styled.div`
  padding-left: 2rem;
`;

export const ListItem = styled.ul`
  display: list-item;
`;

export const OpenedContainer = styled.div`
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const UploadFileWrapper = styled.div`
  position: relative;
  height: calc(100% - 4.5rem);
`;

export const UploadMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const TextWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ButtonWrapper = styled.div<{ $isDisabled: boolean }>`
  position: absolute;
  width: 100%;
  bottom: 0;

  ${StyledButton} {
    box-shadow: ${({ $isDisabled, theme }) => !$isDisabled && `0 2px 20px 2px ${theme.colors.primary.hue300}80`};
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  gap: 0.5rem;
`;

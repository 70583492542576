import { useEffect, useRef, useState } from 'react';

import { Text } from '../text';

import { CodeContainer } from './styles/codeContainer';
import { Container } from './styles/container';
import { StyledError } from './styles/styledError';
import { StyledInput } from './styles/styledInput';

const BACKSPACE = 'Backspace';
const DELETE = 'Delete';
const ARROW_LEFT = 'ArrowLeft';
const ARROW_RIGHT = 'ArrowRight';

export type Props = {
  numInputs: number;
  regex: RegExp;
  onComplete?: () => void;
  onCodeFilled?: (value: string) => void;
  errorMessage?: string;
  disabled?: boolean;
  initialValues?: string[];
};

export const CodeInput: React.FC<Props> = ({
  numInputs,
  regex,
  onComplete,
  errorMessage,
  disabled = false,
  onCodeFilled,
  initialValues = [],
}) => {
  const componentRef = useRef<HTMLInputElement | null>(null);
  const [inputValues, setInputValues] = useState<string[]>(Array.from({ length: numInputs }, () => ''));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [full, setFull] = useState(false);

  useEffect(() => {
    componentRef.current?.focus();
  }, [currentIndex]);

  useEffect(() => {
    const isCompleted = inputValues.every((value) => value.trim() !== '');
    if (isCompleted) {
      setFull(true);
      if (onCodeFilled && onComplete) {
        onCodeFilled(inputValues.join(''));
        onComplete();
      }
      return;
    }
    setFull(false);
  }, [inputValues]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const keyPressed = event.key;

    let newInputValues = [...inputValues];

    if (keyPressed === BACKSPACE || keyPressed === DELETE) {
      newInputValues[currentIndex] = '';
      if (currentIndex !== 0 && inputValues[currentIndex] === '') {
        const previousIndex = currentIndex - 1;
        newInputValues[previousIndex] = '';
        setCurrentIndex(previousIndex);
      }
      setInputValues(newInputValues);
      return;
    }
    if (keyPressed === ARROW_LEFT || keyPressed === ARROW_RIGHT) {
      event.preventDefault();
      return;
    }

    if (!regex.test(keyPressed)) {
      return;
    }
    newInputValues = [...inputValues];
    newInputValues[index] = keyPressed;
    setInputValues(newInputValues);

    if (keyPressed && index < numInputs - 1) {
      const nextIndex = currentIndex + 1;

      setCurrentIndex(nextIndex);
    }
  };

  useEffect(() => {
    if (initialValues.length > 0) {
      setInputValues(initialValues);
    }
  }, [initialValues]);

  const handleClick = () => {
    event?.preventDefault();
    componentRef.current?.focus();
  };

  return (
    <Container>
      <CodeContainer>
        {inputValues.map((value, index) => (
          <StyledInput
            $isActive={currentIndex === index}
            ref={currentIndex === index ? componentRef : null}
            key={index}
            value={value}
            readOnly={initialValues.length > 0}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
            onClick={handleClick}
            disabled={disabled}
            $error={!!errorMessage && full}
            $hasInitialValue={initialValues.length > 0}
          />
        ))}
      </CodeContainer>

      {!!errorMessage && full && !disabled && (
        <StyledError>
          <Text palette="error" textColor="hue100">
            {errorMessage}
          </Text>
        </StyledError>
      )}
    </Container>
  );
};

import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';

export const schema = zod.object({
  documents: zod.array(
    zod.object({
      fileId: zod.string(),
      url: zod.string(),
      name: zod.string(),
      size: zod.number(),
      status: zod.string(),
      file: zod.object({}),
    })
  ),
});

export type FormData = zod.infer<typeof schema>;

export const uploadFilesSchema: FormNames<FormData> = {
  documents: 'documents',
} as const;

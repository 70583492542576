import { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../../../components';
import { DocumentIcon } from '../../../iconography';
import { DraggingPlusIcon } from '../../../iconography/DraggingPlusIcon';

import { AddedFile } from './AddedFile';
import { RejectedFile } from './RejectedFile';
import { AcceptedFileTypes } from './enums';
import {
  FilesContainer,
  UploadFileMultipleStyled,
  UploadFileMultipleStyledWrapper,
  UploadFileMultipleZone,
  UploadFileTextWrapper,
} from './styles/uploadFileMultiple';
import { documentFileTypes, imageFileTypes, UploadedFileMultipleProps } from './types';

const UploadFileMultiple: FC<UploadedFileMultipleProps> = ({
  $width = '600px',
  $height = '400px',
  id,
  isLoading,
  uploadFiles,
  disabled,
  values,
  className,
  dropzoneTitle,
  dropzoneSubtitle,
  acceptedFileTypes = AcceptedFileTypes.ALL,
  onRetry,
  onDelete,
  onViewDocument,
  isDeleteAvailable,
  invalidFileTypeSubtitle,
  invalidFileTypeTitle,
  uploadFailedSubtitle,
  uploadFailedTitle,
  isSubmitLoading,
  maxFiles = 10,
}) => {
  const theme = useTheme();
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    [uploadFiles]
  );

  useEffect(() => {
    if (rejectedFiles.length === 0) return;
    setIsUploadError(true);
  }, [rejectedFiles]);

  const onDropRejected = useCallback(
    // eslint-disable-next-line
    (files: any) => {
      setRejectedFiles((prevState) => [...prevState, ...files]);
    },
    [setRejectedFiles]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
    accept:
      acceptedFileTypes === AcceptedFileTypes.DOCUMENTS
        ? documentFileTypes(acceptedFileTypes)
        : acceptedFileTypes === AcceptedFileTypes.IMAGES
          ? imageFileTypes(acceptedFileTypes)
          : { ...imageFileTypes(acceptedFileTypes), ...documentFileTypes(acceptedFileTypes) },
    maxFiles: maxFiles === 'unlimited' ? undefined : maxFiles,
    maxSize: 10 * 1000000,
    onDropAccepted,
    onDropRejected,
    disabled: disabled || isUploadError || isSubmitLoading,
  });

  const onRejectedDeleteClick = (file: File) => {
    const newRejectedFiles = rejectedFiles.filter((rejectedFile) => rejectedFile !== file);
    setIsUploadError(false);
    setRejectedFiles(newRejectedFiles);
  };

  return (
    <UploadFileMultipleStyledWrapper $width={$width} $height={$height}>
      <UploadFileMultipleStyled
        className={className}
        isLoading={isLoading}
        $isError={isUploadError}
        $isDisabled={disabled || isSubmitLoading}
        key={id}
        {...getRootProps({ isDragAccept, isDragReject, isDragActive })}
      >
        {!isDragActive && !isUploadError && (
          <>
            <input {...getInputProps()} />
            <UploadFileMultipleZone $height={$height} $width={$width}>
              <ThemedIcon icon={DocumentIcon} customStrokeColor={theme.v2.text.bodySecondary} size={'extraLarge'} />
              <UploadFileTextWrapper>
                <Text fontWeight={'bold'} fontSize={'base'} color={theme.v2.text.headingPrimary}>
                  {dropzoneTitle}
                </Text>
              </UploadFileTextWrapper>
              <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.bodySecondary}>
                {dropzoneSubtitle}
              </Text>
            </UploadFileMultipleZone>
          </>
        )}
        {isUploadError && (
          <>
            {rejectedFiles?.map((file) => {
              return (
                <RejectedFile
                  $width={$width}
                  onDeleteClick={() => onRejectedDeleteClick(file)}
                  invalidFileTypeSubtitle={invalidFileTypeSubtitle}
                  invalidFileTypeTitle={invalidFileTypeTitle}
                />
              );
            })}
          </>
        )}

        {isDragActive && (
          <UploadFileMultipleZone $height={$height} $width={$width}>
            <DraggingPlusIcon color={theme.v2.icon.primary} />
          </UploadFileMultipleZone>
        )}
      </UploadFileMultipleStyled>
      {!!values?.length && (
        <FilesContainer>
          {values.map((value) => (
            <AddedFile
              key={value.fileId}
              $width="100%"
              onRetryClick={() => onRetry && onRetry(value.fileId)}
              onDeleteClick={() => onDelete && onDelete(value.fileId)}
              onViewDocumentClick={() => onViewDocument && onViewDocument(value.fileId)}
              isDeleteAvailable={isDeleteAvailable}
              status={value.status}
              name={value.name}
              size={value.size}
              fileId={value.fileId}
              uploadFailedTitle={uploadFailedTitle}
              uploadFailedSubtitle={uploadFailedSubtitle}
              isSubmitLoading={isSubmitLoading}
            />
          ))}
        </FilesContainer>
      )}
    </UploadFileMultipleStyledWrapper>
  );
};

export default UploadFileMultiple;
